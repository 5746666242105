import React from 'react'

const FourOhFour: React.FC = () => {
  return (
    <>
      <h1>404</h1>
    </>
  )
}

export default FourOhFour
